import { ContactUsStyled } from "./contactUs.styles";

export function ContactUs({ id }) {
  return (
    <ContactUsStyled id={id}>
      <h2>Contact Us</h2>
      <p>team@prayfy.app</p>
    </ContactUsStyled>
  );
}
