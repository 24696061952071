import styled from "styled-components";

export const ValueCardStyled = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 0.75rem;
  padding: 2rem 4rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  width: 20rem;

  .container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    flex-direction: column;
  }

  & .emoji {
    font-size: 2rem;
  }

  & .title {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;
  }

  & .description {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.dark_gray};
    line-height: 1.5rem;
  }
`;
