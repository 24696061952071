import { AnimatePresence, motion } from "motion/react";
import { useEffect, useState } from "react";
import { HeroText } from "..";
import { ReactComponent as HeroLogo } from "../../assets/images/heroLogo.svg";
import { HeroLogoContainer, HeroStyled } from "./hero.styles";

const TRANSITION_DURATION = 0.5;
const DELAY_SHOW_LOGO_SECONDS = 2;
const DELAY_HIDE_LOGO_MILISECONDS = 5000;
const DELAY_SHOW_TEXT_SECONDS =
  DELAY_SHOW_LOGO_SECONDS +
  DELAY_HIDE_LOGO_MILISECONDS / 1000 +
  TRANSITION_DURATION;

export function Hero({ id }) {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, DELAY_HIDE_LOGO_MILISECONDS);

    return () => clearTimeout(timer);
  }, []);

  return (
    <HeroStyled id={id}>
      <HeroLogoContainer>
        <AnimatePresence>
          {showLogo && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: TRANSITION_DURATION,
                delay: DELAY_SHOW_LOGO_SECONDS,
              }}
            >
              <HeroLogo />
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: TRANSITION_DURATION,
            delay: DELAY_SHOW_TEXT_SECONDS,
          }}
        >
          <HeroText />
        </motion.div>
      </HeroLogoContainer>
    </HeroStyled>
  );
}
