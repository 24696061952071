import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  padding: 0.2rem;
`;
