import { css } from "styled-components";

const size = {
  mobile: "768px",
  tablet: "1024px",
  desktop: "1440px",
  largeDesktop: "2560px",
};

export const media = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
  largeDesktop: `(min-width: ${size.largeDesktop})`,
};

export const respondTo = Object.keys(media).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media ${media[label]} {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});
