import { ValueCardStyled } from "./valueCard.styles";

export function ValueCard({ emoji, title, description }) {
  return (
    <ValueCardStyled>
      <p className="emoji">{emoji}</p>
      <h3 className="title">{title}</h3>
      <p className="description">{description}</p>
    </ValueCardStyled>
  );
}
