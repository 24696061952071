import ReactMarkdown from "react-markdown";
import rehypeSlug from "rehype-slug"; // Import the new package
import styled from "styled-components";

const MarkdownWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 36px;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #707070;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1rem;

  h1,
  h2,
  h3 {
    font-weight: 700;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 2rem;
    color: #20c2ce;
    border-bottom: 2px solid #2c3e50;
    padding-bottom: 10px;
  }

  h2 {
    font-size: 1.75rem;
    color: #d390e5;
  }

  h3 {
    font-size: 1.5rem;
    color: #8b8b8b;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  ul,
  ol {
    margin: 10px 0;
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
    list-style: outside;
  }

  a {
    color: #007bff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  blockquote {
    border-left: 4px solid #2c3e50;
    padding-left: 15px;
    color: #555;
    font-style: italic;
    margin: 10px 0;
  }

  code {
    font-family: "Courier New", monospace;
    background: #f4f4f4;
    padding: 5px;
    border-radius: 4px;
  }

  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
`;

export const StyledMarkdown = ({ content }) => {
  return (
    <MarkdownWrapper>
      <ReactMarkdown rehypePlugins={[rehypeSlug]}>{content}</ReactMarkdown>
    </MarkdownWrapper>
  );
};
