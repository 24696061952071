import styled from "styled-components";

export const DrawerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  padding: 7rem 2rem;
  gap: 1rem;

  & a {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.5rem;
  }

  & a[class="active"] {
    font-size: 2rem;
  }
`;
