import styled from "styled-components";

export const HeaderStyled = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  position: absolute;
  top: 0;
  width: 100%;
`;
