import { HeroTextContainer } from "./heroText.styles";

export function HeroText() {
  return (
    <HeroTextContainer>
      <p>stay</p>
      <p>connected</p>
      <p>with</p>
      <p>god</p>
      <p>and</p>
      <p>others</p>
    </HeroTextContainer>
  );
}
