import styled from "styled-components";
import { respondTo } from "../../utils/mediaQueries";

export const HeroTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 2rem;

  ${respondTo.mobile`
    width: 80%;
  `}

  ${respondTo.tablet`
    width: 50%;
  `}

  ${respondTo.largeDesktop`
    width: 70%;
  `}

  p {
    font-size: 2rem;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: inline;
    font-weight: 300;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

    ${respondTo.mobile`
      font-size: 3rem;
    `}

    ${respondTo.tablet`
      font-size: 5rem;
    `}

    ${respondTo.desktop`
      font-size: 6rem;
    `}

    ${respondTo.largeDesktop`
      font-size: 8rem;
    `}
  }

  p:nth-child(even) {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;
  }
`;
