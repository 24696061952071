export const theme = {
  colors: {
    primary: "#20C2CE",
    secondary: "#D390E5",
    success: "#2CAB8C",
    danger: "#DC3545",
    warning: "#F9B848",
    info: "#87CEEB",
    light: "#87CEEB",
    dark: "#004387",
    orange: "#FE7C62",
    light_gray: "#FBFBFB",
    dark_gray: "#7B7B7B",
  },
  fonts: {
    tradeMark: "Gochi Hand, cursive",
    text: "Poppins, sans-serif",
  },
  mediaQueries: {
    mobile: "screen and (min-width: 768px)",
    tablet: "screen and (min-width: 992px)",
    desktop: "screen and (min-width: 1200px)",
    large: "screen and (min-width: 1400px)",
  },
};
