import styled from "styled-components";
import { sectionStyles } from "../../styles/section";
import { sectionHeaderStyles } from "../../styles/titles";

export const ContactUsStyled = styled.section`
  ${sectionStyles}
  background-color: ${({ theme }) => theme.colors.secondary};
  align-items: center;
  height: auto;
  align-items: center;
  padding: 2rem;
  gap: 2rem;

  & h2 {
    ${sectionHeaderStyles}
    color: white;
  }

  & p {
    color: white;
  }
`;

export const CardsContainer = styled.section`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
`;
