import { ReactComponent as Logo } from "../../assets/images/prayfy.svg";
import {
  Answer,
  Container,
  EmailLink,
  Page,
  Question,
  Section,
  Subtitle,
  Title,
} from "./support.styles";

export const Support = () => {
  return (
    <Page>
      <Container>
        <Logo />
        <Title>Prayfy Support</Title>

        <Section>
          <Subtitle>
            Welcome to Prayfy Support! We’re here to help you make the most of
            your prayer experience with loved ones.
          </Subtitle>
        </Section>

        <Section>
          <Question>1. How do I create a prayer intention?</Question>
          <Answer>
            Open the app, go to the Intentions page and then tap the “Create
            Intention” button, write your intention, and tag anyone you want to
            pray for.
          </Answer>

          <Question>2. Can I see who has prayed for me?</Question>
          <Answer>
            Yes! You’ll receive a phone notification, and you can also check the
            notifications page to see who has prayed for your intentions.
          </Answer>

          <Question>3. Is Prayfy free?</Question>
          <Answer>
            Prayfy is free for now, but we’ll be introducing premium features
            after the beta version.
          </Answer>

          <Question>
            4. I didn’t receive a notification. What should I do?
          </Question>
          <Answer>
            Make sure notifications are enabled for Prayfy in your phone
            settings.
          </Answer>

          <Question>5. How do I report a bug or give feedback?</Question>
          <Answer>
            Just email us at{" "}
            <EmailLink href="mailto:team@prayfy.app">team@prayfy.app</EmailLink>
            .
          </Answer>
        </Section>

        <Section>
          <p>
            We’re grateful to walk this spiritual journey with you. Your
            feedback and prayers help us grow.
          </p>
        </Section>
      </Container>
    </Page>
  );
};
