import styled from "styled-components";
import { sectionStyles } from "../../styles/section";
import { sectionHeaderStyles } from "../../styles/titles";

export const TestimonialsStyled = styled.section`
  ${sectionStyles}

  & header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    flex-direction: column;
  }

  & h2 {
    ${sectionHeaderStyles}
    color: ${({ theme }) => theme.colors.primary};
    text-align: start;
  }

  & h3 {
    color: ${({ theme }) => theme.colors.dark_gray};
    font-weight: 200;
    line-height: 1.4;
    text-align: start;
  }
`;
