import { useEffect, useState } from "react";
import { StyledMarkdown } from "./MarkdownPage.styled";

export const MarkdownPage = ({ file }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(file)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      });
  }, [file]);

  return <StyledMarkdown content={content} />;
};
