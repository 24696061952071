import { Turn as Hamburger } from "hamburger-react";
import { useDrawer } from "../../stores/useDrawer";
import { Container } from "./menuButton.styles";

export function MenuButton() {
  const { isOpen, toggleDrawer } = useDrawer();

  return (
    <Container>
      <Hamburger
        onToggle={toggleDrawer}
        toggled={isOpen}
        size={24}
        color="#FFFFFF"
      />
    </Container>
  );
}
