import ImportedDrawer from "react-modern-drawer";
import { Link } from "react-scroll";
import { useDrawer } from "../../stores/useDrawer";
import { DrawerStyled } from "./drawer.styles";

export function Drawer() {
  const { isOpen, toggleDrawer } = useDrawer();

  return (
    <ImportedDrawer
      open={isOpen}
      onClose={toggleDrawer}
      className="bla bla bla"
      direction="right"
      animationTime={100}
    >
      <DrawerStyled>
        <Link
          activeClass="active"
          to="hero_section"
          smooth={true}
          duration={500}
          onClick={toggleDrawer}
        >
          Home
        </Link>
        {/* <Link
          activeClass="active"
          to="video_section"
          smooth={true}
          duration={500}
          onClick={toggleDrawer}
        >
          Video
        </Link> */}
        <Link
          activeClass="active"
          to="value_section"
          smooth={true}
          duration={500}
          onClick={toggleDrawer}
        >
          Our App
        </Link>
        {/* <Link
          activeClass="active"
          to="testimonials"
          smooth={true}
          duration={500}
          onClick={toggleDrawer}
        >
          Testimonials
        </Link> */}
        <Link
          activeClass="active"
          to="contact_us"
          smooth={true}
          duration={500}
          onClick={toggleDrawer}
        >
          Contact Us
        </Link>
      </DrawerStyled>
    </ImportedDrawer>
  );
}
