import "react-modern-drawer/dist/index.css";
import { Element } from "react-scroll";
import { ThemeProvider } from "styled-components";
import "../App.css";
import {
  ContactUs,
  Drawer,
  Header,
  Hero,
  MenuButton,
  ValueProposition,
} from "../components";
import { theme } from "../theme/theme";

export function Home() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <Element name="hero_section">
          <Hero />
        </Element>
        {/* <Element name="video_section">
          <Video />
        </Element> */}
        <Element name="value_section">
          <ValueProposition />
        </Element>
        <Element name="contact_us">
          <ContactUs />
        </Element>
        {/* <Element name="testimonials">
          <Testimonials />
        </Element> */}
        <Drawer />
        <MenuButton />
      </div>
    </ThemeProvider>
  );
}
