import styled from "styled-components";
import { sectionStyles } from "../../styles/section";

export const HeroStyled = styled.section`
  ${sectionStyles}

  background-image: radial-gradient(
      at 3.2643956541075436% 25.742373705216792%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 1) 0%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 0) 100%
    ),
    radial-gradient(
      at 43.28087951771826% 18.245864597445415%,
      hsla(184.13793103448276, 73.10924369747899%, 46.666666666666664%, 1) 0%,
      hsla(184.13793103448276, 73.10924369747899%, 46.666666666666664%, 0) 100%
    ),
    radial-gradient(
      at 78.19331057705948% 79.39586553008414%,
      hsla(287.2941176470588, 62.04379562043796%, 73.13725490196079%, 1) 0%,
      hsla(287.2941176470588, 62.04379562043796%, 73.13725490196079%, 0) 100%
    ),
    radial-gradient(
      at 65.49652501076795% 11.148182641376469%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 1) 0%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 0) 100%
    ),
    radial-gradient(
      at 74.1493389385427% 29.76426549117681%,
      hsla(184.13793103448276, 73.10924369747899%, 46.666666666666664%, 1) 0%,
      hsla(184.13793103448276, 73.10924369747899%, 46.666666666666664%, 0) 100%
    ),
    radial-gradient(
      at 18.447616451485406% 91.89415420838998%,
      hsla(287.2941176470588, 62.04379562043796%, 73.13725490196079%, 1) 0%,
      hsla(287.2941176470588, 62.04379562043796%, 73.13725490196079%, 0) 100%
    ),
    radial-gradient(
      at 72.57250168140936% 7.296317935556562%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 1) 0%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 0) 100%
    ),
    radial-gradient(
      at 58.828582710481236% 29.61249561446433%,
      hsla(184.13793103448276, 73.10924369747899%, 46.666666666666664%, 1) 0%,
      hsla(184.13793103448276, 73.10924369747899%, 46.666666666666664%, 0) 100%
    ),
    radial-gradient(
      at 80.7004329902467% 99.7434694261768%,
      hsla(287.2941176470588, 62.04379562043796%, 73.13725490196079%, 1) 0%,
      hsla(287.2941176470588, 62.04379562043796%, 73.13725490196079%, 0) 100%
    ),
    radial-gradient(
      at 25.20355393625846% 63.32469656559472%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 1) 0%,
      hsla(210.2222222222222, 100%, 26.47058823529412%, 0) 100%
    );
`;

export const HeroLogoContainer = styled.div`
  width: 100%;

  & svg {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 80%;
    font-size: 3rem;

    & svg {
      width: 80%;
    }
  }

  @media (min-width: 1200px) {
    width: 65%;

    & svg {
      width: 65%;
    }
  }

  @media (min-width: 1440px) {
    width: 75%;

    & svg {
      width: 75%;
    }
  }
`;
