import styled from "styled-components";
import { radialBackground } from "../../styles/backgrounds";

export const Page = styled.div`
  background-image: ${radialBackground};
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
  color: #fff;
`;

export const Title = styled.h1`
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  line-height: 1.2;
`;

export const Section = styled.div``;

export const Question = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
`;

export const Answer = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const EmailLink = styled.a`
  color: #d390e5;
  text-decoration: none;
  background-color: white;
  padding: 0.1rem 0.3rem;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;
