import styled from "styled-components";
import { sectionStyles } from "../../styles/section";
import { sectionHeaderStyles } from "../../styles/titles";

export const VideoStyled = styled.section`
  ${sectionStyles}
  display: grid;
  flex-direction: column;
  position: relative;
  align-content: space-evenly;
  justify-content: normal;
  gap: 3rem;

  & h2 {
    ${sectionHeaderStyles}
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;

    iframe {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
