import { TestimonialsStyled } from "./testimonials.styles";

import styled from "styled-components";
import romyJose from "../../assets/images/romyJose.jpeg";

export function Testimonials({ id }) {
  return (
    <TestimonialsStyled id={id}>
      <header>
        <h2>Here’s what our alpha testers are saying...</h2>
        <h3>
          Some weeks ago, we joyfully launched the alpha version of Prayfy,
          offering exclusive access to close family and friends. Their
          testimonies not only inspire us but also reaffirm that this project is
          from God.
        </h3>
      </header>
      <section className="testimonialsCarousell">
        <TestimonialCard
          testimonial="Something we’ve loved about the app is knowing who is praying for us."
          author="Romy & Jose"
          image={romyJose}
          backgroundColor="#F9B848"
          videoUrl={"https://www.youtube.com/watch?v=3v9N7yZ2Y7k"}
        />
      </section>
    </TestimonialsStyled>
  );
}

export const TestimonialCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 3rem;
  border-radius: 1.5rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: #fff;

  & .testimonialImage {
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    border: 6px solid rgba(255, 255, 255, 0.25);
  }

  & .testimonialContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & .testimonial {
    font-size: 1.25rem;
    line-height: 1.2;
    text-align: center;
    font-weight: 600;
  }

  & .testimonialAuthor {
    font-size: 1rem;
    text-align: right;
  }

  & .videoUrl {
    background: rgba(0, 0, 0, 0.2);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
    transition: background 0.3s;
  }
`;

export function TestimonialCard({
  testimonial,
  author,
  image,
  backgroundColor,
  videoUrl,
}) {
  return (
    <TestimonialCardStyled $backgroundColor={backgroundColor}>
      <img
        src={image}
        alt={`Testimonial by ${author}`}
        className="testimonialImage"
      />
      <div className="testimonialContainer">
        <p className="testimonial">{`"${testimonial}"`}</p>
        <h4 className="testimonialAuthor">{author}</h4>
      </div>
      {videoUrl && (
        <a className="videoUrl" href={videoUrl}>
          watch video
        </a>
      )}
    </TestimonialCardStyled>
  );
}
