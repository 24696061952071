import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { PATHS } from "./contants/path";
import {
  CookiesPolicy,
  DeleteAccount,
  Home,
  PrivacyPolicy,
  TermsAndConditions,
  DeleteSpecificData,
  Support,
} from "./pages";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path={PATHS.HOME} element={<Home />} />
          <Route
            path={PATHS.TERMS_AND_CONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route path={PATHS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={PATHS.COOKIES_POLICY} element={<CookiesPolicy />} />
          <Route path={PATHS.DELETE_ACCOUNT} element={<DeleteAccount />} />
          <Route
            path={PATHS.DELETE_SPECIFIC_DATA}
            element={<DeleteSpecificData />}
          />
          <Route path={PATHS.SUPPORT} element={<Support />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
