import { ReactComponent as Logo } from "../../assets/images/prayfy.svg";
import { HeaderStyled } from "./header.styles";

export function Header() {
  return (
    <HeaderStyled>
      <div className="logo">
        <Logo />
      </div>
    </HeaderStyled>
  );
}
