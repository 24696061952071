import { ValueCard } from "../valueCard/valueCard";
import {
  ValuePropositionStyled,
  CardsContainer,
} from "./valueProposition.styles";

export function ValueProposition({ id }) {
  return (
    <ValuePropositionStyled id={id}>
      <h2>The App that will boost your prayer life</h2>
      <CardsContainer>
        <ValueCard
          emoji="✅"
          title="Pray More"
          description="Turn prayer into a habit that transforms your daily life"
        />
        <ValueCard
          emoji="🔦"
          title="Stay Focused"
          description="Minimize distractions and make the most of your prayer time."
        />
        <ValueCard
          emoji="🤝"
          title="United in Prayer"
          description="Experience the power of shared prayer with your close circle."
        />
        <ValueCard
          emoji="📌"
          title="Remember"
          description="Never forget to pray for those who ask you to"
        />
        <ValueCard
          emoji="💪"
          title="Support"
          description="Feel the strength and encouragement of your community."
        />
        <ValueCard
          emoji="📡"
          title="Connect"
          description="Live an active faith by connecting with God and your community"
        />
      </CardsContainer>
    </ValuePropositionStyled>
  );
}
